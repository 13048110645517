<template>
<div>
    <vx-card class="mt-8">
        <div class="card-title">
            <h2 class="w-auto my-2">{{ LabelConstant.headingLabelEmailCampaigns }}</h2>
            <vs-button 
                title="Add New Email Campaign" 
                @click="createemailcamp = true,createEditEmailCampaignTitle = LabelConstant.popupTitleCreateEmailCampaign,buttonName = LabelConstant.buttonLabelCreateEmailCampaign"
                type="filled" 
                icon-pack="feather" 
                icon="icon-plus" 
                class="small-btn mr-3 bg-actionbutton"
            >
                {{ LabelConstant.buttonLabelAddNewEmailCampaign }}
            </vs-button>
        </div>
        <div class="common-table">      
            <!--Table Select Row-->
            <div class="table-row-select">
                <vs-select :label="LabelConstant.inputLabelShowEntries" v-model="perPage" class="show-select" autocomplete>
                    <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item, index) in recordList" />
                </vs-select>
            </div>
            <vs-table :data="emailcampaign" ref="tableData" search :max-items="perPage" pagination>
                <template slot="thead">
                    <vs-th>{{ LabelConstant.tableHeaderLabelOptions }}</vs-th>
                    <vs-th sort-key="Campaign_Name">{{ LabelConstant.tableHeaderLabelCampaignName }}</vs-th>
                    <vs-th sort-key="IsActive">{{ LabelConstant.tableHeaderLabelStatus }}</vs-th>
                    <vs-th sort-key="sortableDate">{{ LabelConstant.tableHeaderLabelDateCreated }}</vs-th>
                </template>
                <template slot-scope="{ data }">
                    <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                        <vs-td width="120">
                            <div class="table-action three-action">
                                <vs-button @click="linkCampaign(tr.Id)" color="secondary" :title="LabelConstant.buttonTitleLinkBuilder" type="filled" icon-pack="feather" icon="icon-link" class="mr-2 float-left"></vs-button>
                                <vs-button :title="LabelConstant.buttonTitleEdit" type="filled" icon-pack="feather" icon="icon-edit" class="mr-2 float-left" color="primary" 
                                    @click="editCampaign(tr.Id),createEditEmailCampaignTitle = LabelConstant.popupTitleEditEmailCampaign,buttonName = LabelConstant.buttonLabelUpdateEmailCampaign"
                                >
                                </vs-button>
                                <vs-button @click="deleteEmailCampaing(tr)" :title="LabelConstant.buttonLabelDelete" color="danger" type="filled" icon-pack="feather" icon="icon-trash-2" class="mr-0"></vs-button>
                            </div>
                        </vs-td>
                        <vs-td :data="tr.Campaign_Name">{{ tr.Campaign_Name }}</vs-td>
                        <vs-td>
                            <vs-chip color="success" v-if="tr.IsActive">{{ LabelConstant.statusConditionLabelActive }}</vs-chip>
                            <vs-chip color="danger" v-else>{{ LabelConstant.statusConditionLabelInActive }}</vs-chip>
                        </vs-td>
                        <vs-td :data="tr.sortableDate">{{ tr.displayDate }}</vs-td>
                    </vs-tr>
                </template>
            </vs-table>
            <p class="table-data-entries-details" v-if="!isSearch && isTableRecordEntriesDisplay">
                Showing {{ showingDataFrom }} to {{ showingDataTo }} of {{ emailcampaign.length | tableRecordNumberFormatter }} entries
            </p>
            <p class="table-data-entries-details" v-if="isSearch && isTableRecordEntriesDisplay">
                Showing {{ showingDataFrom }} to {{ showingSearchedDataTo }} of {{ totalSearchedData | tableRecordNumberFormatter }} entries (filtered from {{ emailcampaign.length | tableRecordNumberFormatter }} entries)
            </p>
            <p class="table-data-entries-details" v-if="!isTableRecordEntriesDisplay">
                Showing 0 to 0 of 0 entries
            </p>                 
        </div>    
    </vx-card>
    <!-- Delete Popup -->
    <vs-popup class="delete-popup" :active.sync="deletepopupActive">
        <template>
            <div class="vx-row mb-3">
                <div class="vx-col w-full">
                    <div class="popup-icon">
                        <feather-icon icon="Trash2Icon"></feather-icon>
                    </div>
                    <h4>{{ LabelConstant.popupHeadingLabelDeleteEmailCampaign }}</h4>
                    <span class="deleted-no">{{ campname ? campname : "" }}</span>
                </div>
            </div>
        </template>
        <div class="float-right mt-6 mb-8">
            <vs-button type="filled" @click="deleteE()" color="danger">{{ LabelConstant.buttonLabelDelete }}</vs-button>
            <vs-button @click="deleteCancel()" color="grey" type="border" class="ml-4 grey-btn">{{ LabelConstant.buttonLabelCancel }}</vs-button>
        </div>
    </vs-popup>
    <!-- Create Email Campaigns -->
    <vs-popup class="tag-info email-tag-info" :title="createEditEmailCampaignTitle" :active.sync="createemailcamp">
        <form @submit.prevent="checkValidation()" autocomplete="off" data-vv-scope="campaigninfo">
            <template>
                <div class="vx-row mb-6">
                    <div class="vx-col input-text w-full">
                        <vs-input type="text" class="w-full" :label-placeholder="LabelConstant.inputLabelCampaignName" v-model="campname" name="campname" v-validate="'required|min:1|max:300'" />
                        <span class="text-danger error-msg text-sm">{{errors.first("campaigninfo.campname")}}</span>
                        <span class="text-danger error-msg" v-if="campNameServerSideError">{{ campNameServerSideError }}</span>
                        <p>{{ LabelConstant.inputinfoLabelCampaignName }}</p>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col input-text w-full">
                        <vs-input type="text" class="w-full" :label-placeholder="LabelConstant.inputLabelCampaignSource" v-model="campsource" />
                        <p>{{ LabelConstant.inputinfoLabelCampaignSource }}</p>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col input-text w-full">
                        <vs-input type="text" class="w-full" :label-placeholder="LabelConstant.inputLabelCampaignTerm" v-model="campterm" />
                        <p>{{ LabelConstant.inputinfoLabelCampaignTerm }}</p>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col input-text w-full">
                        <vs-input type="text" class="w-full" :label-placeholder="LabelConstant.inputLabelCampaignContent" v-model="campcontent" />
                        <p>{{ LabelConstant.inputinfoLabelCampaignContent }}</p>
                    </div>
                </div>
                <div class="vx-row">
                    <div class="vx-col input-text w-full">
                        <vs-checkbox v-model="isactive">{{ LabelConstant.inputLableActive }}</vs-checkbox>
                    </div>
                </div>
            </template>
            <div class="flex justify-end w-full mt-6 flex-wrap mb-8">
                <vs-button type="filled" class="mr-0 sm:mr-4 mb-2 sm:mb-0 w-full sm:w-auto sm:mt-0 mt-2" button="submit">{{ buttonName }}</vs-button>
                <vs-button @click="cancelCampaign()" color="grey" type="border" class="w-full sm:w-auto grey-btn">{{ LabelConstant.buttonLabelCancel }}</vs-button>
            </div>
        </form>
    </vs-popup>
    <!-- Email Campaign Link Builder -->
    <vs-popup class="tag-info" :title="LabelConstant.popupTitleEmailCampaignLinkBuilder" :active.sync="linkBuilderActive">
        <form @submit.prevent="checkLinkBuilder()" data-vv-scope="linkbuilder" autocomplete="off">
            <template>
                <div class="vx-row mb-4">
                    <div class="vx-col input-text w-full">
                        <vs-input type="text" class="w-full" :label-placeholder="LabelConstant.inputLabelCampaignName" v-model="campname" :readonly="true" />
                        <p>{{ LabelConstant.inputinfoLabelCampaignName }}</p>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col input-text w-full">
                        <span class="vs-input--label">{{ LabelConstant.inputLabelSelectCampaignType }}</span>
                        <v-select v-model="campaignType" :options="campaignTypeOptions" name="campaignType" label="Campaign_Link_Type" :placeholder="LabelConstant.inputLabelSelectCampaignType" class="email-select" :clearable="false" />
                    </div>
                </div>
            </template>
            <template>
                <p class="mt-10">
                    <b>{{ LabelConstant.inputLabelSelectOptionalFieldsToInclude }}:</b>
                </p>
                <div class="vx-row mt-4 mb-4">
                    <div class="vx-col input-text w-full">
                        <vs-checkbox v-model="companyNameCheck" class="mb-2" v-if="companyShow">{{ LabelConstant.inputLabelCompanyName }}</vs-checkbox>
                        <vs-checkbox v-model="firstNameCheck" class="mb-2" v-if="showFirstName">{{ LabelConstant.inputLabelFirstName }}</vs-checkbox>
                        <vs-checkbox v-model="phoneCheck" class="mb-2" v-if="showPhone">{{ LabelConstant.inputLabelPhoneNumber }}</vs-checkbox>
                        <vs-checkbox v-model="lastNameCheck" class="mb-2" v-if="showLastName">{{ LabelConstant.inputLabelLastName }}</vs-checkbox>
                    </div>
                </div>
                <div class="vx-row mb-4">
                    <div class="vx-col input-text w-full">
                        <vs-input type="text" class="w-full" :label-placeholder="LabelConstant.inputLabelDestinationURL" v-model="destinationUrl" v-validate="{ required: true, url: { require_protocol: true } }" name="destinationUrl" />
                        <span class="text-danger error-msg text-sm">{{errors.first("linkbuilder.destinationUrl")}}</span>
                        <p>{{ LabelConstant.inputinfoLabelDestinationUrl }}</p>
                    </div>
                </div>
                <div class="vx-row mb-8">
                    <div class="vx-col input-text w-full" v-if="showLinkEmail">
                        <vs-input type="text" class="w-full" :label-placeholder="LabelConstant.inputLabelEmail" v-model="linkEmail" name="linkEmail" v-validate="'required|email'" />
                        <span class="text-danger error-msg text-sm">{{errors.first("linkbuilder.linkEmail")}}</span>
                    </div>
                    <div class="vx-col input-text w-full" v-if="companyNameCheck && campaignType.Display_Name == 'individual'">
                        <vs-input type="text" class="w-full" :label-placeholder="LabelConstant.inputLabelCompanyName" v-model="linkCompanyName" name="linkCompanyName" v-validate="'required'" />
                        <span class="text-danger error-msg text-sm">{{errors.first("linkbuilder.linkCompanyName")}}</span>
                    </div>
                    <div class="vx-col input-text w-full" v-if="firstNameCheck && campaignType.Display_Name == 'individual'">
                        <vs-input type="text" class="w-full" :label-placeholder="LabelConstant.inputLabelFirstName" v-model="linkFirstName" name="linkFirstName" v-validate="'required'" />
                        <span class="text-danger error-msg text-sm">{{errors.first("linkbuilder.linkFirstName")}}</span>
                    </div>
                    <div class="vx-col input-text w-full" v-if="lastNameCheck && campaignType.Display_Name == 'individual'">
                        <vs-input type="text" class="w-full" :label-placeholder="LabelConstant.inputLabelLastName" v-model="linkLastName" name="linkLastName" v-validate="'required'" />
                        <span class="text-danger error-msg text-sm">{{errors.first("linkbuilder.linkLastName")}}</span>
                    </div>
                    <div class="vx-col input-text w-full" v-if="phoneCheck && campaignType.Display_Name == 'individual'">
                        <vs-input type="text" class="w-full" :label-placeholder="LabelConstant.inputLabelPhoneNumber" v-model="linkPhoneNumber" name="linkPhoneNumber" v-validate="'required'" />
                        <span class="text-danger error-msg text-sm">{{errors.first("linkbuilder.linkPhoneNumber")}}</span>
                    </div>
                </div>
            </template>
            <div class="flex justify-end w-full mt-6 flex-wrap">
                <vs-button button="submit" type="filled" class="mr-0 sm:mr-4 mb-2 sm:mb-0 w-full sm:w-auto" color="primary">{{ LabelConstant.buttonLabelCreateEmailCampaignLink }}</vs-button>
                <vs-button color="grey" type="border" class="w-full sm:w-auto mb-2 sm:mb-0 grey-btn" @click="cancleLink()">{{ LabelConstant.buttonLabelCancel }}</vs-button>
            </div>
            <div class="float-left w-full mt-6 mb-6">
                <div class="vx-row" v-if="showCutpasteLink">
                    <div class="vx-col input-text w-full">
                        <span class="float-right">
                            <b>{{ LabelConstant.inputLabelCutAndPasteThisLinkIntoYourEmail }}</b>
                        </span>
                        <vs-textarea v-model="textarea" class="builder-textarea" />
                    </div>
                </div>
            </div>
        </form>
    </vs-popup>
    <!-- Help popup -->
    <help-modal :popupActive="popupActive" @close="closepopup"></help-modal>
</div>
</template>

<script>
import VxCard from "@/components/vx-card/VxCard";
/* Filter */
import DateRangePicker from "../../components/filter/DateRangePicker";
import vSelect from "vue-select";
import HelpModal from "../../components/modal/HelpModal";
import {
    Validator
} from "vee-validate";
import moment from "moment";
const dict = {
    custom: {
        campname: {
            required: "Please enter a campaign name",
            min: "Campaign name must be have 1 and less than 300 characters",
            max: "Campaign name must be have 1 and less than 300 characters",
        },
        linkEmail: {
            required: "Please enter email",
            email: "Please enter valid email",
        },
        destinationUrl: {
            required: "Please enter destination url",
            url: "Please enter a valid URL such as http://www.something.com",
        },
        linkCompanyName: {
            required: "Please enter company name",
        },
        linkFirstName: {
            required: "Please enter first name",
        },
        linkLastName: {
            required: "Please enter last name",
        },
        linkPhoneNumber: {
            required: "Please enter phone number",
        }
    },
};
Validator.localize("en", dict);
export default {
    components: {
        VxCard,
        DateRangePicker,
        vSelect,
        HelpModal,
    },
    data() {
        return {
            campNameServerSideError: null,
            textarea: null,
            campname: null,
            campsource: null,
            campterm: null,
            campcontent: null,
            isactive: true,
            campMedium: "",
            campVendor: "",
            cCode: "",

            companyShow: true,
            showFirstName: true,
            showPhone: true,
            showLastName: true,
            showCutpasteLink: false,

            linkEmail: "",
            linkCompanyName: "",
            linkFirstName: "",
            linkLastName: "",
            linkPhoneNumber: "",

            destinationUrl: null,
            lastNameCheck: false,
            phoneCheck: false,
            firstNameCheck: false,
            companyNameCheck: false,
            showLinkEmail: false,

            campaignType: null,
            campaignTypeOptions: [],
            /* Popup */
            popupActive: false,
            deletepopupActive: false,
            createemailcamp: false,
            linkBuilderActive: false,
            campaignId: null,
            perPage: 5,
            recordList: [{
                    text: "5",
                    value: 5,
                },
                {
                    text: "10",
                    value: 10,
                },
                {
                    text: "25",
                    value: 25,
                },
                {
                    text: "50",
                    value: 50,
                },
                {
                    text: "100",
                    value: 100,
                },
            ],
            emailcampaign: [],

            createEditEmailCampaignTitle: null,
            buttonName: null,
        };
    },
    watch: {
        campaignType(val) {
            if (val) {
                this.companyShow = val.Company_Name_Visible;
                this.showFirstName = val.First_Name_Visible;
                this.showPhone = val.Phone_Number_Visible;
                this.showLastName = val.Last_Name_Visible;
                if (val.Display_Name == "individual") {
                    this.showLinkEmail = true;
                } else {
                    this.showLinkEmail = false;
                }
            }
        },
    },
    created() {
        this.getEmailCampaignList();
        this.getCampaignType();
    },
    methods: {
        /* Close Popup */
        closepopup() {
            this.popupActive = false;
            this.detailspopupActive = false;
        },
        checkLinkBuilder() {
            this.$validator.validateAll("linkbuilder").then(async (result) => {
                if (result) {
                    this.linkCampaignCreate();
                }
            });
        },
        checkValidation() {
            this.$validator.validateAll("campaigninfo").then(async (result) => {
                if (result) {
                    this.createUpdateCampaigns();
                }
            });
        },
        async getEmailCampaignList() {
            this.$vs.loading();
            await this.axios
                .get("/ws/ConfigEmailCampaign/GetEmailCampaignList")
                .then((response) => {
                    let data = response.data;
                    this.emailcampaign = data;
                    this.emailcampaign.map(function (manipulatedData) {
                        manipulatedData.displayDate = moment(
                            manipulatedData.Date_Created
                        ).format("M/D/YYYY hh:mm A");
                        manipulatedData.sortableDate = new Date(
                            manipulatedData.Date_Created
                        );
                        return manipulatedData;
                    });
                    this.$vs.loading.close();
                })
                .catch((e) => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        async createUpdateCampaigns() {
            let input = {
                Campaign_Name: this.campname,
                IsActive: this.isactive,
                Id: this.campaignId ? this.campaignId : "",
                Campaign_Code: this.cCode,
                // Campaign_Type: this.campaignType,
                Campaign_Type: "",
                Campaign_Vendor: this.campVendor,
                Campaign_Source: this.campsource,
                Campaign_Medium: this.campMedium,
                Campaign_Term: this.campterm,
                Campaign_Content: this.campcontent,
                IsDeleted: false,
                Date_Created: new Date(),
                Date_Updated: new Date(),
                Updated_By: "",
            };
            this.campNameServerSideError = null;
            let url = this.campaignId ?
                "/ws/ConfigEmailCampaign/UpdateEmailCampaign?id=" + this.campaignId :
                "/ws/ConfigEmailCampaign/CreateEmailCampaign";
            await this.axios
                .post(url, input)
                .then(() => {
                    this.campname = null;
                    this.campsource = null;
                    this.campaignId = null;
                    this.campterm = null;
                    this.campcontent = null;
                    setTimeout(() => {
                        this.errors.clear();
                    }, 100);
                    this.isactive = true;
                    this.createemailcamp = false;
                    this.getEmailCampaignList();
                    this.$vs.loading.close();
                })
                .catch((e) => {
                    this.campNameServerSideError = e.response.data.Errors[0].Message;
                    this.showError(e);
                    this.$vs.loading.close();
                });
        },
        async editCampaign(id) {
            this.$vs.loading();
            await this.axios
                .get("/ws/ConfigEmailCampaign/GetEmailCampaign?id=" + id)
                .then((response) => {
                    let data = response.data;
                    this.campname = data.Campaign_Name;
                    this.isactive = data.IsActive;
                    this.campaignId = data.Id;
                    this.campcontent = data.Campaign_Content;
                    this.campsource = data.Campaign_Source;
                    this.campterm = data.Campaign_Term;

                    this.campVendor = data.Campaign_Vendor;
                    this.campMedium = data.Medium;
                    this.cCode = data.Campaign_Code;
                    this.campaignType = data.Campaign_Type;
                    this.createemailcamp = true;
                    this.$vs.loading.close();
                })
                .catch((e) => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        async linkCampaign(id) {
            this.$vs.loading();
            await this.axios
                .get("/ws/ConfigEmailCampaign/GetEmailCampaign?id=" + id)
                .then((response) => {
                    let data = response.data;
                    this.campname = data.Campaign_Name;
                    this.isactive = data.IsActive;
                    this.campaignId = data.Id;
                    this.campcontent = data.Campaign_Content;
                    this.campsource = data.Campaign_Source;
                    this.campterm = data.Campaign_Term;

                    this.campVendor = data.Campaign_Vendor;
                    this.campMedium = data.Medium;
                    this.cCode = data.Campaign_Code;
                    this.campaignType = data.Campaign_Type;
                    if (!data.Campaign_Type) {
                        setTimeout(() => {
                            this.campaignTypeOptions.forEach((element) => {
                                if (element.Display_Name == "constantcontact") {
                                    this.campaignType = element;
                                }
                            });
                        }, 200);
                    }
                    this.linkBuilderActive = true;
                    this.linkCompanyName = "";
                    this.linkFirstName = "";
                    this.linkLastName = "";
                    this.linkPhoneNumber = "";
                    this.linkEmail = "";
                    this.$vs.loading.close();
                })
                .catch((e) => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        async deleteEmailCampaing(data) {
            this.campname = data.Campaign_Name;
            this.campaignId = data.Id;
            this.deletepopupActive = true;
        },
        deleteCancel() {
            this.campname = null;
            this.campaignId = null;
            this.deletepopupActive = false;
            setTimeout(() => {
                this.errors.clear();
            }, 100);
        },
        async deleteE() {
            this.$vs.loading();
            await this.axios
                .get("/ws/ConfigEmailCampaign/DeleteEmailCampaign?id=" + this.campaignId)
                .then(() => {
                    this.campname = null;
                    this.campaignId = null;
                    this.deletepopupActive = false;
                    this.getEmailCampaignList();
                    this.$vs.loading.close();
                })
                .catch((e) => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        async linkCampaignCreate() {
            let input = {
                Id: this.campaignId,
                Campaign_Name: this.campname,
                Campaign_Code: this.cCode,
                Campaign_Type: "",
                Campaign_Vendor: this.campVendor,
                Campaign_Source: this.campsource,
                Campaign_Medium: this.campMedium,
                Campaign_Term: this.campterm,
                Campaign_Content: this.campcontent,
                IsActive: this.isactive,
                IsDeleted: false,
                Date_Created: new Date(),
                Date_Updated: new Date(),
                Updated_By: "",
                Email: this.linkEmail,
                Company_Name: this.linkCompanyName,
                Phone_Number: this.linkPhoneNumber,
                First_Name: this.linkFirstName,
                Last_Name: this.linkLastName,
                Campaign_Link_Type: this.campaignType.Display_Name,
                Installation_Code: "",
                Company_Name_Check: this.companyNameCheck,
                First_Name_Check: this.firstNameCheck,
                Last_Name_Check: this.lastNameCheck,
                Phone_Number_Check: this.phoneCheck,
                Destination_URL: this.destinationUrl,
            };
            await this.axios
                .post("/ws/ConfigEmailCampaign/CreateEmailCampaignLink", input)
                .then((response) => {
                    this.showCutpasteLink = true;
                    this.textarea = response.data.Link;
                    this.$vs.loading.close();
                })
                .catch((e) => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        async getCampaignType() {
            this.$vs.loading();
            await this.axios
                .get("/ws/ConfigEmailCampaign/GetAvailableEmailCampaignSystems")
                .then((response) => {
                    let data = response.data;
                    this.campaignTypeOptions = data;
                    setTimeout(() => {
                        this.campaignTypeOptions.forEach((element) => {
                            if (element.Display_Name == "constantcontact") {
                                this.campaignType = element;
                            }
                        });
                    }, 200);
                    this.$vs.loading.close();
                })
                .catch((e) => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        cancelCampaign() {
            this.campname = null;
            this.isactive = true;
            this.campaignId = null;
            this.campcontent = null;
            this.campsource = null;
            this.campterm = null;

            this.campVendor = null;
            this.campMedium = null;
            this.cCode = null;
            this.campaignType = null;
            this.createemailcamp = false;
            this.linkEmail = "",
            this.linkCompanyName = "",
            this.linkFirstName = "",
            this.linkLastName = "",
            this.linkPhoneNumber = "",
            setTimeout(() => {
                this.errors.clear();
            }, 100);
        },
        cancleLink() {
            this.campaignId = null;
            this.campname = null;
            this.cCode = null;
            this.campVendor = null;
            this.campsource = null;
            this.campMedium = null;
            this.campterm = null;
            this.campcontent = null;
            this.isactive = true;
            this.campaignType = null;
            this.companyNameCheck = false;
            this.firstNameCheck = false;
            this.lastNameCheck = false;
            this.phoneCheck = false;
            this.destinationUrl = null;
            this.linkBuilderActive = false;
            this.showCutpasteLink = false;
            this.linkEmail = "";
            this.textarea = null;
            setTimeout(() => {
                this.errors.clear();
            }, 100);
        },
    },
    computed: {
        isTableRecordEntriesDisplay() {
            if (this.emailcampaign.length > 0) {
                if (this.$refs.tableData.datax.length > 0) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        },
        isSearch: function () {
            if (this.emailcampaign.length > 0) {
                if (
                    this.$refs.tableData.searchx == null ||
                    this.$refs.tableData.searchx == ""
                ) {
                    return false;
                } else {
                    return true;
                }
            }
        },
        showingDataFrom: function () {
            if (this.emailcampaign.length > 0) {
                return (this.$refs.tableData.currentx - 1) * this.perPage + 1;
            }
        },
        showingDataTo: function () {
            if (this.emailcampaign.length > 0) {
                let totalPages = this.$refs.tableData.getTotalPages;
                if (totalPages == this.$refs.tableData.currentx) {
                    return (
                        (this.$refs.tableData.currentx - 1) * this.perPage +
                        this.$refs.tableData.datax.length
                    );
                } else {
                    return this.$refs.tableData.currentx * this.perPage;
                }
            }
        },
        totalSearchedData: function () {
            if (this.emailcampaign.length > 0) {
                return this.$refs.tableData.queriedResults.length;
            }
        },
        showingSearchedDataTo: function () {
            if (this.emailcampaign.length > 0) {
                let totalPages = this.$refs.tableData.getTotalPagesSearch;
                if (totalPages == this.$refs.tableData.currentx) {
                    return (
                        (this.$refs.tableData.currentx - 1) * this.perPage +
                        this.$refs.tableData.datax.length
                    );
                } else {
                    return this.$refs.tableData.currentx * this.perPage;
                }
            }
        },
    },
};
</script>
